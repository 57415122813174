const createPopupCanvas = () => {
  const canvas = document.createElement("div");
  const background = document.createElement("div");
  canvas.setAttribute("klickart-popups-canvas", "");
  canvas.style.display = "none";
  background.classList.add("popup-background");
  canvas.appendChild(background);

  return document.body.appendChild(canvas);
};

const klickartPublicPopup = () => {
  const popupBackground = document.querySelector(".popup-background");
  const popupCanvas = document.querySelector("[klickart-popups-canvas]");
  const popupHolder = document.querySelector("[klickart-popups]");
  const popupsLinks = document.querySelectorAll(
    '[klickart-edit-link-type="popup"]'
  );

  const generateClosePopupFunction = function (popup) {
    return function () {
      popupBackground.style.display = "none";
      popupCanvas.style.display = "none";
      popup.style.display = "none";
      popupHolder.appendChild(popup);
      document.body.style.overflow = "";
    };
  };

  const createCloseButton = function (popup) {
    popup.style.position = "relative";
    let popupCloseButton = popup.querySelector("fancybox-close");

    if (popupCloseButton) {
      return popupCloseButton;
    }

    popupCloseButton = document.createElement("a");
    popupCloseButton.className = "fancybox-close";
    popup.appendChild(popupCloseButton);

    return popupCloseButton;
  };

  return [...popupsLinks].forEach((popupLink) => {
    const popupId = popupLink.getAttribute("href").replace("#", "");
    const popup = document.getElementById(popupId);
    const closePopupFunction = generateClosePopupFunction(popup);
    const closeButton = createCloseButton(popup);

    popupLink.addEventListener("click", () => {
      popupCanvas.appendChild(popup);
      popupBackground.style.display = "block";
      popupBackground.onclick = closePopupFunction;
      closeButton.onclick = closePopupFunction;
      popupCanvas.style.display = "flex";
      popup.style.display = "block";
      document.body.style.overflow = "hidden";
    });
  });
};

export { createPopupCanvas, klickartPublicPopup };
